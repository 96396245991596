import React, { createContext } from 'react';
import SummaryPage from './SummaryPage';
import GamesPage from './GamesPage';
import StandingsPage from './StandingsPage';
import Random from './Random';

import { DataContext } from './contexts';

const App = () => {
  const name = 'John Doe';
  const score = 10;
  const teams = [
    { id: 1, name: 'Team A' },
    { id: 2, name: 'Team B' },
    { id: 3, name: 'Team C' },
  ];

  const games = [
    { id: 1, team1: 'Team A', team2: 'Team B', score: '2-1', outcome: 'Win', points: 3 },
    { id: 2, team1: 'Team C', team2: 'Team A', score: '0-0', outcome: 'Draw', points: 1 },
    { id: 3, team1: 'Team B', team2: 'Team C', score: '1-3', outcome: 'Loss', points: 0 },
  ];

  const participants = [
    { id: 1, name: 'John Doe', score: 10 },
    { id: 2, name: 'Jane Smith', score: 8 },
    { id: 3, name: 'Bob Johnson', score: 6 },
  ];

  return (
      <DataContext.Provider>
        <div>
            {/* <SummaryPage name={name} score={score} teams={teams} />
            <GamesPage games={games} />
            <StandingsPage participants={participants} /> */}
            <Random></Random>
        </div>
      </DataContext.Provider>
  );
};

export default App;
