import React, { useState } from 'react';
import { Box, Button, Input, InputLabel, Paper, TextField } from '@mui/material';
import allTeams from './data/teams.json';

const teams = allTeams.teams;

function cryptoRandom() {
    return window.crypto.getRandomValues(new Uint32Array(1))[0] / 0x100000000;
}

function cryptoRandomIntX(min, max) {
    return Math.floor((cryptoRandom() % (max + 1)));
}

function cryptoRandomInt(min, max) {
    const randomBuffer = new Uint32Array(1);

    window.crypto.getRandomValues(randomBuffer);

    let randomNumber = randomBuffer[0] / (0xffffffff + 1);

    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(randomNumber * (max - min + 1)) + min;
}

const Random = ({ }) => {
    const [drawnTeams, setDrawnTeams] = useState([]);
    const [playerName, setPlayerName] = useState([]);
    const drawTeams = () => {
        debugger;
        const selectedTeams = [];
        const selectableTeamsRank1 = teams.filter(
            a => a.group_rank === 1
        );
        const idx = cryptoRandomInt(0, selectableTeamsRank1.length - 1);
        selectedTeams.push(selectableTeamsRank1[idx]);
        
        let count = 0;
        while (count < 1) {
            const selectableTeamsRank2 = teams.filter(
                a => a.group_rank === 2
            );
            const idx = cryptoRandomInt(0, selectableTeamsRank2.length - 1);
            const currentTeam = selectableTeamsRank2[idx];
            if (!selectedTeams.find(a => a.group === currentTeam.group)) {
                selectedTeams.push(currentTeam);
                count++;
            }
        }
        
        count = 0
        while (count < 2) {
            const selectableTeamsRank2 = teams.filter(
                a => a.group_rank > 2
            );
            const idx = cryptoRandomInt(0, selectableTeamsRank2.length - 1);
            const currentTeam = selectableTeamsRank2[idx];
            if (!selectedTeams.find(a => a.group === currentTeam.group)) {
                selectedTeams.push(currentTeam);
                count++;
            }
        }
        debugger;
        setDrawnTeams(selectedTeams);

    }
    return (
        <Paper variant='outlined' component="form" sx={{ display: 'grid', alignItems: 'center', alignContent: 'center', width: '250px'}}>
            <Box sx={{ flex: 2}}>
                <TextField
                    label='Name'
                    variant='outlined'
                    value={playerName}
                    onChange={(event => setPlayerName(event.target.value))}
                >
                    
                </TextField>

            </Box>

            <Button variant='contained' onClick={() => drawTeams()}>Draw</Button>
            
            {drawnTeams.map((team) => (
                <tr key={team}>
                <td>{team.country}</td>
                <td>{team.group}</td>
                <td>{team.group_rank}</td>
                <td>{team.fifa_world_ranking}</td>
                </tr>
            ))}
        </Paper>
    );
}

export default Random;